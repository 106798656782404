// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".categories_SgiQS{width:100%}.categories__container_uW\\+kQ{overflow:auto;background:rgba(0,0,0,0);display:grid;grid-template-columns:repeat(5, 150px);grid-gap:8px;width:100%;padding-bottom:8px;overflow-x:auto}.categories__container_uW\\+kQ::-webkit-scrollbar{width:16px;outline:none;padding:0 4px}.categories__container_uW\\+kQ::-webkit-scrollbar-track{width:8px;background:#232f3f;padding:0 4px}.categories__container_uW\\+kQ::-webkit-scrollbar-thumb{background:#455566;border-radius:2px;border-left:4px solid #232f3f;border-right:4px solid #232f3f}@media(min-width: 1024px){.categories__container_uW\\+kQ{overflow:hidden;grid-template-columns:repeat(5, 1fr);grid-gap:16px}}.categories__containerOneLine_CSwH8{grid-template-columns:repeat(9, 150px)}@media(min-width: 1024px){.categories__containerOneLine_CSwH8{grid-template-columns:repeat(5, 1fr)}}.card_5PEAK{display:grid;grid-template-columns:1fr;grid-template-rows:40px 1fr;grid-gap:8px;padding:12px;background:#1c2735;border-radius:12px;text-align:center;cursor:pointer}.cardActive_toDBT{border:1px solid #2196f3}.icon_AINUu{height:40px;width:40px;margin:auto}.iconActive_nNDXe *{fill:#2196f3}.text_to4tT{color:#fff;font-size:16px;font-weight:500;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categories": "categories_SgiQS",
	"categories__container": "categories__container_uW+kQ",
	"categories__containerOneLine": "categories__containerOneLine_CSwH8",
	"card": "card_5PEAK",
	"cardActive": "cardActive_toDBT",
	"icon": "icon_AINUu",
	"iconActive": "iconActive_nNDXe",
	"text": "text_to4tT"
};
module.exports = ___CSS_LOADER_EXPORT___;
